




























import { Component, Prop } from "vue-property-decorator";
import Finish from "@/components/designer/sidebar/frame/Finish.vue";
import { mixins } from "vue-class-component";
import { FrameStore, LayoutStore } from "@/mixins/store";
import { Finish as FinishModel } from "@/models/products/finish";
import { GetFinishImage } from "@/mixins/helpers";

@Component({
  components: {
    Finish
  }
})
export default class FinishGroup extends mixins(
  FrameStore,
  LayoutStore,
  GetFinishImage
) {
  @Prop() finishes!: FinishModel[];
  @Prop() hasTPUFinialFinish!: boolean;
  protected selected: FinishModel | null = null;

  created(): void {
    this.readStoreData();
  }

  /**
   * Check the store for preset data. If there is none, set default
   * selected to the first one in finish array
   */
  protected readStoreData(): void {
    if (!this.finish) {
      this.selected = this.finishes[0];
      this.addFinish(this.selected);
    } else {
      this.selected = this.finish;
    }
  }

  protected async select(finish: FinishModel): Promise<void> {
    this.selected = finish;
    this.addFinish(finish);
    await this.$viewer.ChangeFrameFinish(finish.handle);
    if (this.finial && this.hasTPUFinialFinish) {
      this.addFinialFinish(finish);
      await this.$viewer.ChangeFinial(this.finial, finish.handle);
    }
  }
}
