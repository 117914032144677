






































































































































































import { Component, Watch, Prop } from "vue-property-decorator";
import Finial from "@/components/designer/sidebar/frame/Finial.vue";
import RadioInput from "@/components/designer/reusable/RadioInput.vue";
import UIkit from "uikit";
import { mixins } from "vue-class-component";
import { FrameStore, LayoutStore } from "@/mixins/store";
import {
  BottomPole,
  Finial as FinialEnum,
  FinialFinishType
} from "@/models/configurator/configurator_options";
import { ProductLineName } from "@/models/products/collection";
import {
  Finish,
  FinishDisplayName,
  FinishHandle
} from "@/models/products/finish";
import { StockMixin } from "@/mixins/stock";

@Component({
  components: {
    Finial,
    RadioInput
  }
})
export default class FinialGroup extends mixins(
  FrameStore,
  LayoutStore,
  StockMixin
) {
  @Prop() hasTPUFinialFinish!: boolean;
  protected FinialEnum = FinialEnum;
  protected BottomPole = BottomPole;
  protected FinishHandle = FinishHandle;
  protected FinialFinishType = FinialFinishType;
  protected selectedFinial: FinialEnum | null = null;
  protected selectedFinialFinishType: FinialFinishType | null = null;
  protected selectedPole: BottomPole | null = null;
  protected ProductLineName = ProductLineName;

  mounted(): void {
    this.readStoreData();
  }

  get showBottomPole(): boolean {
    let show = false;
    if (
      this.collection.handle === ProductLineName.MontereyPulleyLift ||
      this.collection.handle === ProductLineName.MontereyAutoTilt ||
      this.collection.handle === ProductLineName.MontereyCrank ||
      this.collection.handle === ProductLineName.Greenwich ||
      this.collection.handle === ProductLineName.Catalina ||
      this.collection.handle === ProductLineName.Laurel
    ) {
      show = true;
    }
    return show;
  }

  get showFinialFinish(): boolean {
    let show = false;
    if (
      this.collection.handle === ProductLineName.GreenwichGSeries ||
      this.collection.handle === ProductLineName.MontereyGSeries ||
      this.collection.handle === ProductLineName.MontereyPulleyLift ||
      this.collection.handle === ProductLineName.MontereyAutoTilt ||
      this.collection.handle === ProductLineName.MontereyCrank ||
      this.collection.handle === ProductLineName.Greenwich
    ) {
      show = true;
    }
    return show;
  }

  get hasTPUFinialFinishOption(): boolean {
    switch (this.collection.handle) {
      case ProductLineName.MontereyPulleyLift:
      case ProductLineName.MontereyCrank:
      case ProductLineName.MontereyAutoTilt:
        return true;
      default:
        return false;
    }
  }

  /**
   * Get the selected finial finish based on the selected finial finish type.
   * TPU types will match the frame finish, SS will be SS, and C (default) will be chrome.
   */
  get selectedFinialFinish(): Finish {
    switch (this.selectedFinialFinishType) {
      case FinialFinishType.TPU:
        return this.finish;
      case FinialFinishType.SS:
        return { handle: FinishHandle.SS, display_name: FinishDisplayName.SS };
      case FinialFinishType.CH:
        return { handle: FinishHandle.CH, display_name: FinishDisplayName.CH };
      default:
        return {} as Finish;
    }
  }

  /**
   * Check the store for preset data. If there is none, set default
   * selected to the first one in finish array
   */
  protected async readStoreData(): Promise<void> {
    if (!this.bottomPole) {
      this.selectedPole = BottomPole.Standard;
      await this.addBottomPole(BottomPole.Standard);
      await this.$viewer.ChangeBottomPole(this.selectedPole!);
    } else {
      this.selectedPole = this.bottomPole;
    }

    if (!this.finial && this.showFinialFinish) {
      this.selectedFinial = FinialEnum.Ball;
      await this.addFinial(FinialEnum.Ball);
    } else {
      this.selectedFinial = this.finial;
    }

    this.selectedFinialFinishType = this.showFinialFinish
      ? this.setFinialFinishType()
      : null;
    await this.addFinialFinish(this.selectedFinialFinish);
    if (this.finialFinish.handle === 0 && this.finial) {
      await this.changeViewerFinial();
    }
  }

  /**
   * Set the finial finish type based on the pre-approved collection handles.
   * Note: must be in sync with the showFinialFinish getter, etc.
   */
  protected setFinialFinishType(): FinialFinishType {
    if (this.hasTPUFinialFinish) {
      return FinialFinishType.TPU;
    } else if (this.finial && this.finialFinish.handle === FinishHandle.SS) {
      return FinialFinishType.SS;
    } else {
      return FinialFinishType.CH;
    }
  }

  @Watch("selectedPole")
  protected async selectPole(): Promise<void> {
    if (this.selectedPole) {
      await this.addBottomPole(this.selectedPole);
      await this.$viewer.ChangeBottomPole(this.selectedPole);
    }
  }

  protected async selectFinial(
    finial: FinialEnum | null = this.finial
  ): Promise<void> {
    this.selectedFinial = finial;
    if (finial) {
      await this.addFinial(finial);
      await this.addFinialFinish(this.selectedFinialFinish);
      await this.changeViewerFinial();
    }
  }

  protected async changeViewerFinial(): Promise<void> {
    await this.$viewer.ChangeFinial(
      this.finial as FinialEnum,
      this.finialFinish.handle
    );
  }

  protected toggleModal(): void {
    UIkit.modal(document.getElementById("bottom-pole") as HTMLElement).show();
  }
}
