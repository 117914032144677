























































































































import { Component, Prop } from "vue-property-decorator";
import FinishGroup from "@/components/designer/sidebar/frame/FinishGroup.vue";
import FinialGroup from "@/components/designer/sidebar/frame/FinialGroup.vue";
import SpigotGroup from "@/components/designer/sidebar/frame/SpigotGroup.vue";
import { mixins } from "vue-class-component";
import { FrameStore, LayoutStore, ShapeStore } from "@/mixins/store";
import { Finish, FinishHandle } from "@/models/products/finish";
import UIkit from "uikit";
import { ProductLineName } from "@/models/products/collection";
import { StockMixin } from "@/mixins/stock";

@Component({
  components: {
    FinishGroup,
    FinialGroup,
    SpigotGroup
  }
})
export default class FrameSidebar extends mixins(
  ShapeStore,
  LayoutStore,
  StockMixin,
  FrameStore
) {
  protected finishes: Finish[] | null = null;
  protected ProductLineName = ProductLineName;
  @Prop({ default: true }) hasVent!: boolean;
  @Prop({ default: true }) hasValance!: boolean;
  async mounted(): Promise<void> {
    this.finishes = this.umbrellaModel!.finishes;
  }

  protected showSpigot(): boolean {
    if (
      this.collection.handle === ProductLineName.Aurora ||
      this.collection.handle === ProductLineName.Eclipse
    ) {
      return true;
    } else {
      return false;
    }
  }

  protected hasTPUFinialFinish(): boolean {
    if (!this.finial || !this.hasTPUFinialFinishTypeOption()) return false;
    switch (this.finialFinish.handle) {
      case FinishHandle.CH:
      case FinishHandle.SS:
        return false;
      default:
        return true;
    }
  }

  protected hasTPUFinialFinishTypeOption(): boolean {
    switch (this.collection.handle) {
      case ProductLineName.MontereyPulleyLift:
      case ProductLineName.MontereyCrank:
      case ProductLineName.MontereyAutoTilt:
        return true;
      default:
        return false;
    }
  }

  protected toggleFinishModal(): void {
    UIkit.modal(document.getElementById("finish-modal") as HTMLElement).show();
  }

  protected toggleFinialModal(): void {
    UIkit.modal(document.getElementById("finial-modal") as HTMLElement).show();
  }
}
